import {Injectable} from '@angular/core';
import {HttpClient, HttpParams, HttpResponse} from '@angular/common/http';
import {saveAs} from 'file-saver';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';

@Injectable({
    providedIn: 'root',
})
export class FileService {

    constructor(private http: HttpClient) {
    }

    downloadFile(url: string, parameters?: any, fileName?: string): Observable<HttpResponse<Blob>> {
        let params = new HttpParams();
        if (parameters) {
            Object.entries(parameters).forEach(([key, value]) => {
                params = params.append(key, value.toString());
            });
        }

        return this.http.get(url, {observe: 'response', responseType: 'blob', params: params})
            .pipe(
                map((response: HttpResponse<Blob>) => {
                    saveAs(response.body, fileName);
                    return response;
                })
            );
    }
}
