/* tslint:disable:max-line-length */

import {NgModule} from '@angular/core';

import {DocumentsService} from '../../../controllers/Documents';
import {FormsSharedModule} from '../../forms-shared.module';
import {DocumentsDeleteFormService} from './delete.service';


@NgModule({
  imports: [
    FormsSharedModule,
  ],
  providers: [
    DocumentsService,
    DocumentsDeleteFormService,
  ],
})
export class DocumentsDeleteModule {}
