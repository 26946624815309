/* tslint:disable:max-line-length */

import {NgModule} from '@angular/core';

import {IndexDocumentsService} from '../../../controllers/IndexDocuments';
import {FormsSharedModule} from '../../forms-shared.module';
import {IndexDocumentsIndexDocumentsFormService} from './indexDocuments.service';


@NgModule({
  imports: [
    FormsSharedModule,
  ],
  providers: [
    IndexDocumentsService,
    IndexDocumentsIndexDocumentsFormService,
  ],
})
export class IndexDocumentsIndexDocumentsModule {}
