import {NgModule} from '@angular/core';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import {MatBottomSheetModule} from '@angular/material/bottom-sheet';
import { MatButtonModule } from '@angular/material/button';
import {MatButtonToggleModule} from '@angular/material/button-toggle';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MAT_DATE_LOCALE, MatRippleModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogModule } from '@angular/material/dialog';
import { MatDividerModule } from '@angular/material/divider';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';
import { MatPaginatorModule, MatPaginatorIntl } from '@angular/material/paginator';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSelectModule } from '@angular/material/select';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatSnackBarModule, MAT_SNACK_BAR_DEFAULT_OPTIONS } from '@angular/material/snack-bar';
import {MatSortModule} from '@angular/material/sort';
import { MatStepperModule } from '@angular/material/stepper';
import { MatTableModule } from '@angular/material/table';
import { MatTabsModule } from '@angular/material/tabs';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatTreeModule } from '@angular/material/tree';
import {MatMomentDateModule} from '@angular/material-moment-adapter';
import {COMMA, ENTER} from '@angular/cdk/keycodes';
import {MatBadgeModule} from '@angular/material/badge';
import {
    MatChipsModule,
    MAT_CHIPS_DEFAULT_OPTIONS
} from '@angular/material/chips';

// 3rd Material components
import {NgSelectModule} from '@ng-select/ng-select';


@NgModule({
    imports: [
        MatButtonModule,
        MatCheckboxModule,
        MatFormFieldModule,
        MatSelectModule,
        MatInputModule,
        MatIconModule,
        MatDividerModule,
        MatSnackBarModule,
        MatTableModule,
        MatPaginatorModule,
        MatProgressSpinnerModule,
        MatAutocompleteModule,
        MatDialogModule,
        MatCardModule,
        MatListModule,
        MatChipsModule,
        MatDatepickerModule,
        MatMomentDateModule,
        MatTabsModule,
        MatTreeModule,
        MatStepperModule,
        MatExpansionModule,
        MatMenuModule,
        MatSlideToggleModule,
        MatProgressBarModule,
        MatAutocompleteModule,
        MatBottomSheetModule,
        MatSortModule,
        MatButtonToggleModule,

        MatRippleModule,
        MatToolbarModule,
        MatGridListModule,

        // 3rd Party Modules
        NgSelectModule
    ],
    exports: [
        MatButtonModule,
        MatCheckboxModule,
        MatFormFieldModule,
        MatSelectModule,
        MatInputModule,
        MatIconModule,
        MatDividerModule,
        MatSnackBarModule,
        MatTableModule,
        MatPaginatorModule,
        MatProgressSpinnerModule,
        MatAutocompleteModule,
        MatDialogModule,
        MatCardModule,
        MatListModule,
        MatChipsModule,
        MatDatepickerModule,
        MatMomentDateModule,
        MatTabsModule,
        MatTreeModule,
        MatStepperModule,
        MatExpansionModule,
        MatMenuModule,
        MatSlideToggleModule,
        MatProgressBarModule,
        MatRippleModule,
        MatToolbarModule,
        MatGridListModule,
        MatBadgeModule,
        MatAutocompleteModule,
        MatBottomSheetModule,
        MatSortModule,
        MatButtonToggleModule,

        // 3rd Party Modules
        NgSelectModule
    ],
    providers: [
        {provide: MAT_SNACK_BAR_DEFAULT_OPTIONS, useValue: {duration: 4000}},
        {
            provide: MAT_CHIPS_DEFAULT_OPTIONS,
            useValue: {
                separatorKeyCodes: [ENTER, COMMA]
            }
        },
        {provide: MAT_DATE_LOCALE, useValue: 'en-US'},
    ]
})
export class ProjectMaterialModule {
}
