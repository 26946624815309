/* tslint:disable:max-line-length */

import {NgModule} from '@angular/core';

import {DocumentsService} from '../../../controllers/Documents';
import {FormsSharedModule} from '../../forms-shared.module';
import {DocumentsCheckIfExistsFormService} from './checkIfExists.service';


@NgModule({
  imports: [
    FormsSharedModule,
  ],
  providers: [
    DocumentsService,
    DocumentsCheckIfExistsFormService,
  ],
})
export class DocumentsCheckIfExistsModule {}
