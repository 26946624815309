/* tslint:disable:max-line-length */

import {NgModule} from '@angular/core';

import { AuthFormModule } from './auth/auth.module';
import { CountriesFormModule } from './countries/countries.module';
import { DocumentLanguagesFormModule } from './document-languages/document-languages.module';
import { DocumentTypesFormModule } from './document-types/document-types.module';
import { DocumentsFormModule } from './documents/documents.module';
import { IndexDocumentsFormModule } from './index-documents/index-documents.module';
import { KeywordsFormModule } from './keywords/keywords.module';
import { WordpressUsersFormModule } from './wordpress-users/wordpress-users.module';

@NgModule({
  imports: [
    AuthFormModule,
    CountriesFormModule,
    DocumentLanguagesFormModule,
    DocumentTypesFormModule,
    DocumentsFormModule,
    IndexDocumentsFormModule,
    KeywordsFormModule,
    WordpressUsersFormModule,
  ],
  exports: [
    AuthFormModule,
    CountriesFormModule,
    DocumentLanguagesFormModule,
    DocumentTypesFormModule,
    DocumentsFormModule,
    IndexDocumentsFormModule,
    KeywordsFormModule,
    WordpressUsersFormModule,
  ],
})
export class ApiFormsModule {}
