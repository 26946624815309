/* tslint:disable:max-line-length */

import {NgModule} from '@angular/core';

import {DocumentsService} from '../../../controllers/Documents';
import {FormsSharedModule} from '../../forms-shared.module';
import {DocumentsWordpressGroupsFormService} from './wordpressGroups.service';


@NgModule({
  imports: [
    FormsSharedModule,
  ],
  providers: [
    DocumentsService,
    DocumentsWordpressGroupsFormService,
  ],
})
export class DocumentsWordpressGroupsModule {}
