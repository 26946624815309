/* tslint:disable:max-line-length */

import {HttpClient, HttpParams} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import { APIConfigService } from '../apiconfig.service';

import * as __model from '../model';

export interface ListParams {
  /** A search term. */
  search?: string;
  main_category?: string;
  /** Multiple values may be separated by commas. */
  keywords?: string;
  /** Multiple values may be separated by commas. */
  countries?: string;
  /** Multiple values may be separated by commas. */
  types?: string;
  /** Multiple values may be separated by commas. */
  languages?: string;
  link?: string;
  /** Number of results to return per page. */
  limit?: number;
  /** The initial index from which to return the results. */
  offset?: number;
  /** List of fields */
  fields?: string;
  /** List of nested objects */
  expand?: string;
}

export interface CreateParams {
  data: __model.DocumentWrite;
  /** List of fields */
  fields?: string;
  /** List of nested objects */
  expand?: string;
}

export interface CheckIfExistsParams {
  /** A search term. */
  search?: string;
  main_category?: string;
  /** Multiple values may be separated by commas. */
  keywords?: string;
  /** Multiple values may be separated by commas. */
  countries?: string;
  /** Multiple values may be separated by commas. */
  types?: string;
  /** Multiple values may be separated by commas. */
  languages?: string;
  link?: string;
  /** Number of results to return per page. */
  limit?: number;
  /** The initial index from which to return the results. */
  offset?: number;
  /** document link */
  document_link?: string;
  /** List of fields */
  fields?: string;
  /** List of nested objects */
  expand?: string;
}

export interface WordpressGroupsParams {
  /** A search term. */
  search?: string;
  main_category?: string;
  /** Multiple values may be separated by commas. */
  keywords?: string;
  /** Multiple values may be separated by commas. */
  countries?: string;
  /** Multiple values may be separated by commas. */
  types?: string;
  /** Multiple values may be separated by commas. */
  languages?: string;
  link?: string;
  /** Number of results to return per page. */
  limit?: number;
  /** The initial index from which to return the results. */
  offset?: number;
  /** List of fields */
  fields?: string;
  /** List of nested objects */
  expand?: string;
}

export interface ReadParams {
  /** List of fields */
  fields?: string;
  /** List of nested objects */
  expand?: string;
  /** A unique integer value identifying this document. */
  id: number;
}

export interface UpdateParams {
  data: __model.DocumentWrite;
  /** List of fields */
  fields?: string;
  /** List of nested objects */
  expand?: string;
  /** A unique integer value identifying this document. */
  id: number;
}

export interface PartialUpdateParams {
  data: __model.DocumentWrite;
  /** List of fields */
  fields?: string;
  /** List of nested objects */
  expand?: string;
  /** A unique integer value identifying this document. */
  id: number;
}

export interface DeleteParams {
  /** A unique integer value identifying this document. */
  id: number;
}

@Injectable()
export class DocumentsService {
  constructor(
    private http: HttpClient,
    private apiConfigService: APIConfigService) {}


  list(params: ListParams, multipart = false): Observable<__model.DocumentsList> {
    const queryParamBase = {
      search: params.search,
      main_category: params.main_category,
      keywords: params.keywords,
      countries: params.countries,
      types: params.types,
      languages: params.languages,
      link: params.link,
      limit: params.limit,
      offset: params.offset,
      fields: params.fields,
      expand: params.expand,
    };

    let queryParams = new HttpParams();
    Object.entries(queryParamBase).forEach(([key, value]) => {
      if (value !== undefined && value !== null) {
        if (Array.isArray(value)) {
          let val = '';
          value.forEach(v => val += v + ',');
          if (val.length > 0 ) {
            val = val.slice(0, val.length - 1);
          }
          queryParams = queryParams.set(key, val);
        } else if (typeof value === 'string') {
          queryParams = queryParams.set(key, value);
        } else {
          queryParams = queryParams.set(key, JSON.stringify(value));
        }
      }
    });

    return this.http.get<__model.DocumentsList>(this.apiConfigService.options.apiUrl + `/api/documents/`, {params: queryParams});
  }

  create(params: CreateParams, multipart = false): Observable<__model.DocumentWrite> {
    const bodyParams = params.data;
    const bodyParamsWithoutUndefined: any = (multipart) ? new FormData() : Array.isArray(bodyParams) ? [] : {};
    Object.entries(bodyParams || {}).forEach(([key, value]) => {
      if (value !== undefined) {
        if (multipart) {
          bodyParamsWithoutUndefined.append(key, value);
        } else {
          bodyParamsWithoutUndefined[key] = value;
        }
      }
    });
    const queryParamBase = {
      fields: params.fields,
      expand: params.expand,
    };

    let queryParams = new HttpParams();
    Object.entries(queryParamBase).forEach(([key, value]) => {
      if (value !== undefined && value !== null) {
        if (Array.isArray(value)) {
          let val = '';
          value.forEach(v => val += v + ',');
          if (val.length > 0 ) {
            val = val.slice(0, val.length - 1);
          }
          queryParams = queryParams.set(key, val);
        } else if (typeof value === 'string') {
          queryParams = queryParams.set(key, value);
        } else {
          queryParams = queryParams.set(key, JSON.stringify(value));
        }
      }
    });

    return this.http.post<__model.DocumentWrite>(this.apiConfigService.options.apiUrl + `/api/documents/`, bodyParamsWithoutUndefined, {params: queryParams});
  }

  checkIfExists(params: CheckIfExistsParams, multipart = false): Observable<__model.DocumentsCheckIfExists> {
    const queryParamBase = {
      search: params.search,
      main_category: params.main_category,
      keywords: params.keywords,
      countries: params.countries,
      types: params.types,
      languages: params.languages,
      link: params.link,
      limit: params.limit,
      offset: params.offset,
      document_link: params.document_link,
      fields: params.fields,
      expand: params.expand,
    };

    let queryParams = new HttpParams();
    Object.entries(queryParamBase).forEach(([key, value]) => {
      if (value !== undefined && value !== null) {
        if (Array.isArray(value)) {
          let val = '';
          value.forEach(v => val += v + ',');
          if (val.length > 0 ) {
            val = val.slice(0, val.length - 1);
          }
          queryParams = queryParams.set(key, val);
        } else if (typeof value === 'string') {
          queryParams = queryParams.set(key, value);
        } else {
          queryParams = queryParams.set(key, JSON.stringify(value));
        }
      }
    });

    return this.http.get<__model.DocumentsCheckIfExists>(this.apiConfigService.options.apiUrl + `/api/documents/check_if_exists/`, {params: queryParams});
  }

  wordpressGroups(params: WordpressGroupsParams, multipart = false): Observable<__model.WordpressGroup[]> {
    const queryParamBase = {
      search: params.search,
      main_category: params.main_category,
      keywords: params.keywords,
      countries: params.countries,
      types: params.types,
      languages: params.languages,
      link: params.link,
      limit: params.limit,
      offset: params.offset,
      fields: params.fields,
      expand: params.expand,
    };

    let queryParams = new HttpParams();
    Object.entries(queryParamBase).forEach(([key, value]) => {
      if (value !== undefined && value !== null) {
        if (Array.isArray(value)) {
          let val = '';
          value.forEach(v => val += v + ',');
          if (val.length > 0 ) {
            val = val.slice(0, val.length - 1);
          }
          queryParams = queryParams.set(key, val);
        } else if (typeof value === 'string') {
          queryParams = queryParams.set(key, value);
        } else {
          queryParams = queryParams.set(key, JSON.stringify(value));
        }
      }
    });

    return this.http.get<__model.WordpressGroup[]>(this.apiConfigService.options.apiUrl + `/api/documents/wordpress_groups/`, {params: queryParams});
  }

  read(params: ReadParams, multipart = false): Observable<__model.Document> {
    const queryParamBase = {
      fields: params.fields,
      expand: params.expand,
    };

    let queryParams = new HttpParams();
    Object.entries(queryParamBase).forEach(([key, value]) => {
      if (value !== undefined && value !== null) {
        if (Array.isArray(value)) {
          let val = '';
          value.forEach(v => val += v + ',');
          if (val.length > 0 ) {
            val = val.slice(0, val.length - 1);
          }
          queryParams = queryParams.set(key, val);
        } else if (typeof value === 'string') {
          queryParams = queryParams.set(key, value);
        } else {
          queryParams = queryParams.set(key, JSON.stringify(value));
        }
      }
    });

    const pathParams = {
      id: params.id,
    };
    return this.http.get<__model.Document>(this.apiConfigService.options.apiUrl + `/api/documents/${pathParams.id}/`, {params: queryParams});
  }

  update(params: UpdateParams, multipart = false): Observable<__model.DocumentWrite> {
    const bodyParams = params.data;
    const bodyParamsWithoutUndefined: any = (multipart) ? new FormData() : Array.isArray(bodyParams) ? [] : {};
    Object.entries(bodyParams || {}).forEach(([key, value]) => {
      if (value !== undefined) {
        if (multipart) {
          bodyParamsWithoutUndefined.append(key, value);
        } else {
          bodyParamsWithoutUndefined[key] = value;
        }
      }
    });
    const queryParamBase = {
      fields: params.fields,
      expand: params.expand,
    };

    let queryParams = new HttpParams();
    Object.entries(queryParamBase).forEach(([key, value]) => {
      if (value !== undefined && value !== null) {
        if (Array.isArray(value)) {
          let val = '';
          value.forEach(v => val += v + ',');
          if (val.length > 0 ) {
            val = val.slice(0, val.length - 1);
          }
          queryParams = queryParams.set(key, val);
        } else if (typeof value === 'string') {
          queryParams = queryParams.set(key, value);
        } else {
          queryParams = queryParams.set(key, JSON.stringify(value));
        }
      }
    });

    const pathParams = {
      id: params.id,
    };
    return this.http.put<__model.DocumentWrite>(this.apiConfigService.options.apiUrl + `/api/documents/${pathParams.id}/`, bodyParamsWithoutUndefined, {params: queryParams});
  }

  partialUpdate(params: PartialUpdateParams, multipart = false): Observable<__model.DocumentWrite> {
    const bodyParams = params.data;
    const bodyParamsWithoutUndefined: any = (multipart) ? new FormData() : Array.isArray(bodyParams) ? [] : {};
    Object.entries(bodyParams || {}).forEach(([key, value]) => {
      if (value !== undefined) {
        if (multipart) {
          bodyParamsWithoutUndefined.append(key, value);
        } else {
          bodyParamsWithoutUndefined[key] = value;
        }
      }
    });
    const queryParamBase = {
      fields: params.fields,
      expand: params.expand,
    };

    let queryParams = new HttpParams();
    Object.entries(queryParamBase).forEach(([key, value]) => {
      if (value !== undefined && value !== null) {
        if (Array.isArray(value)) {
          let val = '';
          value.forEach(v => val += v + ',');
          if (val.length > 0 ) {
            val = val.slice(0, val.length - 1);
          }
          queryParams = queryParams.set(key, val);
        } else if (typeof value === 'string') {
          queryParams = queryParams.set(key, value);
        } else {
          queryParams = queryParams.set(key, JSON.stringify(value));
        }
      }
    });

    const pathParams = {
      id: params.id,
    };
    return this.http.patch<__model.DocumentWrite>(this.apiConfigService.options.apiUrl + `/api/documents/${pathParams.id}/`, bodyParamsWithoutUndefined, {params: queryParams});
  }

  delete(params: DeleteParams, multipart = false): Observable<string> {
    const pathParams = {
      id: params.id,
    };
    return this.http.delete(this.apiConfigService.options.apiUrl + `/api/documents/${pathParams.id}/`, {responseType: 'text'});
  }
}
