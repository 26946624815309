<div fxFill fxFlex="100" class="padding-40">
    <mat-progress-bar mode="indeterminate" color="primary"
                      *ngIf="(_wordpressUsersLoginFormService.loading$ | async)"></mat-progress-bar>
    <mat-card class="mat-elevation-z4">
        <mat-card-content fxFlex="100" fxLayout="column" fxLayoutGap="12px" fxLayoutAlign="center center"
                          [formGroup]="_wordpressUsersLoginFormService.form.controls.data">
            <h1 class="bold"> Welcome to Observatory </h1>
            <mat-form-field fxFlex="100" class="width-30" appearance="outline" floatLabel="always">
                <mat-label> Username</mat-label>
                <input matInput placeholder="Username" autocomplete="off" formControlName="username">
            </mat-form-field>
            <mat-form-field fxFlex="100" class="width-30" appearance="outline" floatLabel="always">
                <mat-label> Password</mat-label>
                <input matInput [type]="hidePassword ? 'password' : 'text'" placeholder="Password" autocomplete="off"
                       formControlName="password">
                <mat-icon matSuffix (click)="hidePassword = !hidePassword">
                    {{ hidePassword ? 'visibility' : 'visibility_off' }}
                </mat-icon>
            </mat-form-field>
            <button mat-raised-button type="button" color="primary" class="width-30" (click)="login()"
                    [disabled]="_wordpressUsersLoginFormService.form.controls.data.invalid || (_wordpressUsersLoginFormService.loading$ | async)">
                <mat-icon>login</mat-icon>
                Login
            </button>
        </mat-card-content>
    </mat-card>
</div>
