/* tslint:disable:max-line-length */

import {NgModule} from '@angular/core';

import {DocumentTypesService} from '../../../controllers/DocumentTypes';
import {FormsSharedModule} from '../../forms-shared.module';
import {DocumentTypesDocumentTypesFormService} from './documentTypes.service';


@NgModule({
  imports: [
    FormsSharedModule,
  ],
  providers: [
    DocumentTypesService,
    DocumentTypesDocumentTypesFormService,
  ],
})
export class DocumentTypesDocumentTypesModule {}
