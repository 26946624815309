/* tslint:disable:max-line-length */

import {NgModule} from '@angular/core';

import {WordpressUsersService} from '../../../controllers/WordpressUsers';
import {FormsSharedModule} from '../../forms-shared.module';
import {WordpressUsersReadFormService} from './read.service';


@NgModule({
  imports: [
    FormsSharedModule,
  ],
  providers: [
    WordpressUsersService,
    WordpressUsersReadFormService,
  ],
})
export class WordpressUsersReadModule {}
