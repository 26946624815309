import {Injectable} from '@angular/core';
import {
    HttpEvent,
    HttpInterceptor,
    HttpHandler,
    HttpRequest,
    HttpErrorResponse,
    HttpHeaders
} from '@angular/common/http';
import {Observable, throwError, of} from 'rxjs';
import {ActivatedRoute, Params, Router} from '@angular/router';
import {catchError} from 'rxjs/internal/operators';
import {MatSnackBar} from '@angular/material/snack-bar';
import {AuthService} from "../services/auth.service";

@Injectable()
export class AuthInterceptor implements HttpInterceptor {

    private redirectUrl = null;

    constructor(
        private _router: Router,
        private _authService: AuthService,
        private _matSnackBar: MatSnackBar,
        private _activatedRoute: ActivatedRoute,
    ) {
    }

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        let ln = localStorage.language || window.navigator.language || 'en';
        ln = ln.split('-')[0];
        const language = ln;

        let headers = {};
        if (this._authService.getToken()) {
            headers = {
                'Authorization': 'WordpressUserToken ' + this._authService.getToken(),
                'Accept-Language': language
            };
            // } else {
            //     this._activatedRoute.queryParams
            //         .subscribe((queryParams: Params) => {
            //             console.log(queryParams);
            //             if (queryParams.source === undefined) {
            //                 localStorage.setItem('redirectUrl', `${window.location.origin}/apps`);
            //                 this._router.navigate(['/apps/login']).then();
            //                 return;
            //             }
            //
            //             if (queryParams.source === 'plugin') {
            //                 localStorage.setItem('redirectUrl', window.location.href);
            //                 this._router.navigate(['/apps/login']).then();
            //             }
            //         });
        }
        const authReq = req.clone({
            headers: new HttpHeaders(headers)
        });

        return next.handle(authReq).pipe(catchError((error, caught) => {
            this.handleAuthError(error);
            return throwError(error);
        }) as any);
    }

    private handleAuthError(err: HttpErrorResponse): Observable<any> {
        const translate1 = `User access not allowed`;
        const translate2 = `Close`;

        if (err.status === 401) {
            if (window.location.href.search(/apps/) !== -1) {
                if (window.location.pathname !== '/') {
                    this._matSnackBar.open(
                        translate1,
                        translate2 || 'X',
                        {panelClass: 'error'}
                    );
                }
                localStorage.clear();
                this._router.navigate(['/apps/login']).then();
            }

            return of(err.message);
        } else if (err.status === 400) {

            if (err && err.error && err.error.non_field_errors) {
                this.showError(err.error.non_field_errors);
            }
            return of(err.message);
        } else if (err.status === 500) {

            return of(err.message);
        }
        throw err;
    }

    private showError(error: any, parent?: string): void {
        const translate1 = 'Close';
        if (Array.isArray(error)) {
            error.forEach((err) => {
                this.showError(err, parent);
            });
        } else if (typeof error === 'object') {
            Object.keys(error).map((key) => {
                this.showError(error[key], parent + ' ' + key);
            });
        } else {
            if (parent) {
                this._matSnackBar.open(
                    parent + ': ' + error,
                    translate1 || 'X',
                    {panelClass: 'error'}
                );
            } else {
                this._matSnackBar.open(
                    error,
                    translate1 || 'X',
                    {panelClass: 'error'}
                );
            }
        }
    }
}
