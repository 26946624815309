/* tslint:disable:max-line-length */

import {NgModule} from '@angular/core';

import {CountriesService} from '../../../controllers/Countries';
import {FormsSharedModule} from '../../forms-shared.module';
import {CountriesCountriesFormService} from './countries.service';


@NgModule({
  imports: [
    FormsSharedModule,
  ],
  providers: [
    CountriesService,
    CountriesCountriesFormService,
  ],
})
export class CountriesCountriesModule {}
