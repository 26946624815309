import {AfterViewInit, Component} from '@angular/core';
import {ActivatedRoute, Params, Router} from "@angular/router";
import {DocumentsCreateFormService} from "../../../../api/forms/documents/create/create.service";
import {DocumentsPartialUpdateFormService} from "../../../../api/forms/documents/partialUpdate/partialUpdate.service";
import {DocumentLanguagesDocumentLanguagesFormService} from "../../../../api/forms/document-languages/documentLanguages/documentLanguages.service";
import {CountriesCountriesFormService} from "../../../../api/forms/countries/countries/countries.service";
import {DocumentTypesDocumentTypesFormService} from "../../../../api/forms/document-types/documentTypes/documentTypes.service";
import {KeywordsKeywordsFormService} from "../../../../api/forms/keywords/keywords/keywords.service";
import {
    Country,
    DocumentLanguage,
    DocumentType,
    CountriesCountries,
    DocumentLanguagesDocumentLanguages,
    Document,
    DocumentTypesDocumentTypes, KeywordsKeywords, DocumentWrite
} from 'api/model';
import {DocumentsReadFormService} from 'api/forms/documents/read/read.service';
import {map} from 'rxjs/operators';
import {AuthService} from "../../../shared/services/auth.service";

@Component({
    selector: 'app-document-detail',
    templateUrl: './document-detail.component.html',
    styleUrls: ['./document-detail.component.scss']
})
export class DocumentDetailComponent implements AfterViewInit {

    public id: number;
    public public_area = false;
    public private_area = false;
    public keywords: any[] = [];
    public main_category: any[] = [
        {id: 1, name: 'Research', code: 'research'},
        {id: 2, name: 'Financial', code: 'financial'},
        {id: 3, name: 'Statistics', code: 'statistics'},
        {id: 4, name: 'Environment', code: 'environment'},
        {id: 6, name: 'Regulation', code: 'regulation'},
        {id: 7, name: 'Administrative', code: 'administrative'},
        {id: 8, name: 'Promotion', code: 'promotion'},
        {id: 9, name: 'Chemistry', code: 'chemistry'},
        {id: 10, name: 'Technical Cooperation', code: 'technical_cooperation'},
        {id: 11, name: 'Council Meeting', code: 'council_meeting'},
        {id: 12, name: 'College Meeting', code: 'college_meeting'},
    ];
    public countries: Country[] = [];
    public types: DocumentType[] = [];
    public languages: DocumentLanguage[] = [];
    public formService: DocumentsCreateFormService | DocumentsPartialUpdateFormService;

    constructor(
        private _router: Router,
        private _authService: AuthService,
        private _activatedRoute: ActivatedRoute,
        private _documentsReadFormService: DocumentsReadFormService,
        private _documentsCreateFormService: DocumentsCreateFormService,
        private _keywordsKeywordsFormService: KeywordsKeywordsFormService,
        private _countriesCountriesFormService: CountriesCountriesFormService,
        private _documentsPartialUpdateFormService: DocumentsPartialUpdateFormService,
        private _documentTypesDocumentTypesFormService: DocumentTypesDocumentTypesFormService,
        private _documentLanguagesDocumentLanguagesFormService: DocumentLanguagesDocumentLanguagesFormService,
    ) {
        this._activatedRoute.queryParams
            .subscribe((queryParams: Params) => {
                if (queryParams.origin === 'plugin' && !this._authService.getToken()) {
                    localStorage.setItem('redirectUrl', window.location.href);
                    this._router.navigate(['/apps/login']).then();
                }
            });

        this.load();

        this._activatedRoute.params
            .subscribe((params: Params) => {
                this.id = params.id || null;
                this.formService = this.id ? this._documentsPartialUpdateFormService : this._documentsCreateFormService;
                this.formService.reset();
                if (!this.id) {
                    this._activatedRoute.queryParams
                        .subscribe(queryParams => {
                            if (queryParams.origin === 'plugin' && !this._authService.getToken()) {
                                localStorage.setItem('redirectUrl', window.location.href);
                                this._router.navigate(['/apps/login']).then();
                            }

                            let date = null;
                            if (queryParams.date) {
                                const year = queryParams.date.toString().split('/')[2];
                                const month = queryParams.date.toString().split('/')[1];
                                const day = queryParams.date.toString().split('/')[0];
                                date = `${year}-${month}-${day}`;
                            }
                            this.public_area = queryParams.source === 'public_area';
                            this.private_area = queryParams.source === 'private_area';
                            if (!this.public_area && this.private_area) {
                                this.formService.form.controls.data.patchValue({
                                    information_source: queryParams.source,
                                    visibility: queryParams.visibility,
                                    name: queryParams.name,
                                    date: date,
                                    link: queryParams.link,
                                });
                                return;
                            }

                            this.formService.form.controls.data.patchValue({
                                information_source: queryParams.source,
                                visibility: queryParams.visibility,
                            });
                        });
                }
            });
    }

    ngAfterViewInit(): void {
        if (this.id) {
            this._documentsReadFormService.submit({
                id: this.id,
                expand: 'countries,keywords,languages,types,main_category'
            }).pipe(
                map((response: Document) => {
                    return {
                        ...response,
                        main_category: {
                            id: this.main_category.filter(e => e.code.toString().toLowerCase() === response.main_category)[0].id,
                            code: response.main_category,
                            name: this.main_category.filter(e => e.code === response.main_category).length > 0 ? this.main_category.filter(e => e.code === response.main_category)[0].name : null
                        }
                    }
                })
            ).subscribe((response: any) => {
                this.public_area = response.information_source === 'public_area';
                this.private_area = response.information_source === 'private_area';
                this.formService.form.patchValue({data: {...response}, id: this.id});
            });
        }
    }

    load(): void {
        this._countriesCountriesFormService.submit({limit: 9999, fields: 'id,name'})
            .subscribe((response: CountriesCountries) => this.countries = response.results);

        this._documentLanguagesDocumentLanguagesFormService.submit({limit: 9999, fields: 'id,name'})
            .subscribe((response: DocumentLanguagesDocumentLanguages) => this.languages = response.results);

        this._documentTypesDocumentTypesFormService.submit({limit: 9999, fields: 'id,name'})
            .subscribe((response: DocumentTypesDocumentTypes) => this.types = response.results);

        this._keywordsKeywordsFormService.submit({limit: 9999, fields: 'id,name'})
            .subscribe((response: KeywordsKeywords) => this.keywords = response.results);
    }

    onToppingRemoved(id: number, property: string) {
        if (property === 'main_category') {
            this.formService.form.get(`data.${property}`).setValue(null);
        } else {
            let data = this.formService.form.get(`data.${property}`).value as any[];
            data = data.filter(e => e.id !== id);
            this.formService.form.get(`data.${property}`).setValue(data);
        }
    }

    save(): void {
        const formCopyValue = Object.assign({}, this.formService.form.controls.data.value);
        formCopyValue.main_category = formCopyValue.main_category.code.toString().toLowerCase();
        formCopyValue.countries = formCopyValue.countries.map(e => {
            return e.id
        });
        formCopyValue.languages = formCopyValue.languages.map(e => {
            return e.id
        });
        formCopyValue.types = formCopyValue.types.map(e => {
            return e.id
        });
        formCopyValue.keywords = formCopyValue.keywords.map(e => {
            return e.id
        });
        this.formService.submit({
            data: formCopyValue,
            id: formCopyValue.id
        }).subscribe((response: DocumentWrite) =>
            this._router.navigate([`/apps/document-detail/${response.id}`]).then());
    }

    compareFn(o1: any, o2: any): boolean {
        return o1.id == o2.id;
    }

    logout(): void {
        this._authService.logout();
    }
}
