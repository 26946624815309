/* tslint:disable:max-line-length */

import {NgModule} from '@angular/core';

import { CountriesCountriesModule } from './countries/countries.module';

@NgModule({
  imports: [
    CountriesCountriesModule,
  ],
  exports: [
    CountriesCountriesModule,
  ],
})
export class CountriesFormModule {}
