/* tslint:disable:max-line-length */

import {NgModule} from '@angular/core';

import { WordpressUsersListModule } from './list/list.module';
import { WordpressUsersLoginModule } from './login/login.module';
import { WordpressUsersReadModule } from './read/read.module';

@NgModule({
  imports: [
    WordpressUsersListModule,
    WordpressUsersLoginModule,
    WordpressUsersReadModule,
  ],
  exports: [
    WordpressUsersListModule,
    WordpressUsersLoginModule,
    WordpressUsersReadModule,
  ],
})
export class WordpressUsersFormModule {}
