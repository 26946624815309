/* tslint:disable:max-line-length */

import {HttpClient, HttpParams} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import { APIConfigService } from '../apiconfig.service';

import * as __model from '../model';

export interface IndexDocumentsParams {
  /** A search term. */
  search?: string;
  /** Number of results to return per page. */
  limit?: number;
  /** The initial index from which to return the results. */
  offset?: number;
  /** Main category of the document */
  main_category?: string;
  /** From date (Year/Month/Day) */
  from_date?: string;
  /** To date (Year/Month/Day) */
  to_date?: string;
  /** Keywords of the document */
  keywords?: string;
  /** Countries of the document */
  countries?: string;
  /** Types of the document */
  types?: string;
  /** Languages of the document */
  languages?: string;
  /** Groups of the document */
  groups?: string;
  /** List of fields */
  fields?: string;
  /** List of nested objects */
  expand?: string;
}

@Injectable()
export class IndexDocumentsService {
  constructor(
    private http: HttpClient,
    private apiConfigService: APIConfigService) {}


  indexDocuments(params: IndexDocumentsParams, multipart = false): Observable<__model.IndexDocumentsIndexDocuments> {
    const queryParamBase = {
      search: params.search,
      limit: params.limit,
      offset: params.offset,
      main_category: params.main_category,
      from_date: params.from_date,
      to_date: params.to_date,
      keywords: params.keywords,
      countries: params.countries,
      types: params.types,
      languages: params.languages,
      groups: params.groups,
      fields: params.fields,
      expand: params.expand,
    };

    let queryParams = new HttpParams();
    Object.entries(queryParamBase).forEach(([key, value]) => {
      if (value !== undefined && value !== null) {
        if (Array.isArray(value)) {
          let val = '';
          value.forEach(v => val += v + ',');
          if (val.length > 0 ) {
            val = val.slice(0, val.length - 1);
          }
          queryParams = queryParams.set(key, val);
        } else if (typeof value === 'string') {
          queryParams = queryParams.set(key, value);
        } else {
          queryParams = queryParams.set(key, JSON.stringify(value));
        }
      }
    });

    return this.http.get<__model.IndexDocumentsIndexDocuments>(this.apiConfigService.options.apiUrl + `/api/index-documents/`, {params: queryParams});
  }
}
