<div fxFill fxFlex="100" fxLayout="column" fxLayoutGap="12px" class="padding-40" *ngIf="formService">
    <div fxFlex="100" fxLayout="row" fxLayoutAlign="start center">
        <a mat-raised-button type="button" color="primary" (click)="logout()"
           [disabled]="(formService.loading$ | async)">
            <mat-icon> logout</mat-icon>
            LOGOUT
        </a>
    </div>
    <mat-progress-bar mode="indeterminate" color="primary"
                      *ngIf="(formService.loading$ | async)"></mat-progress-bar>
    <mat-card class="mat-elevation-z4">
        <mat-card-content fxFlex="100" fxLayout="column" fxLayoutGap="12px">
            <div fxFlex="100" fxLayout="row" fxLayoutAlign="space-between center" fxLayoutGap="12px">
                <button mat-raised-button type="button" color="primary" [disabled]="(formService.loading$ | async)"
                        [routerLink]="id ? '/apps/document-detail/' + id : '/apps'">
                    <mat-icon> arrow_left</mat-icon>
                    {{ id ? 'GO TO DETAIL DOCUMENT' : 'GO TO DOCUMENTS' }}
                </button>
                <button mat-raised-button type="button" color="primary" (click)="save()"
                        [disabled]="formService.form.controls.data.invalid || (formService.loading$ | async)">
                    <mat-icon> done</mat-icon>
                    SAVE DOCUMENT
                </button>
            </div>
            <div fxFlex="100" fxLayout="row wrap" fxLayout.lt-md="column" fxLayoutGap="12px grid"
                 [formGroup]="formService.form.controls.data">
                <div fxFlex="100" *ngIf="private_area">
                    <h3> Editing metadata for file: </h3>
                    <h2 class="bold">
                        <a target="_blank" [href]="formService.form.controls.data.value.link">
                            {{ formService.form.controls.data.value.link }} </a>
                    </h2>
                </div>
                <mat-form-field fxFlex="50" appearance="outline" floatLabel="always" *ngIf="public_area">
                    <mat-label> Url of the document (with a button to check it)</mat-label>
                    <input matInput placeholder="Url" formControlName="link">
                </mat-form-field>
                <mat-form-field fxFlex="50" appearance="outline" floatLabel="always">
                    <mat-label> Date of the document</mat-label>
                    <input matInput [matDatepicker]="picker" placeholder="Date" formControlName="date">
                    <mat-datepicker-toggle matSuffix [for]="picker" (click)="picker.open()"></mat-datepicker-toggle>
                    <mat-datepicker #picker></mat-datepicker>
                </mat-form-field>
                <mat-form-field fxFlex="50" appearance="outline" floatLabel="always">
                    <mat-label> Name of the document</mat-label>
                    <input matInput placeholder="Name" formControlName="name">
                </mat-form-field>
                <mat-form-field fxFlex="50" appearance="outline" floatLabel="always">
                    <mat-label>Main category</mat-label>
                    <mat-select formControlName="main_category" [compareWith]="compareFn">
                        <mat-select-trigger>
                            <mat-chip-list>
                                <mat-chip [removable]="true"
                                          (removed)="onToppingRemoved(formService.form.get('data.main_category').value?.id, 'main_category')">
                                    {{ formService.form.get('data.main_category').value?.name }}
                                    <mat-icon matChipRemove>cancel</mat-icon>
                                </mat-chip>
                            </mat-chip-list>
                        </mat-select-trigger>
                        <mat-option *ngFor="let item of main_category" [value]="item"> {{ item.name }} </mat-option>
                    </mat-select>
                </mat-form-field>
                <mat-form-field fxFlex="100" appearance="outline" floatLabel="always">
                    <mat-label> Description</mat-label>
                    <textarea matInput placeholder="Description" formControlName="description"></textarea>
                </mat-form-field>
                <mat-form-field fxFlex="50" appearance="outline" floatLabel="always">
                    <mat-label>Related origin</mat-label>
                    <mat-select formControlName="countries" [compareWith]="compareFn" multiple>
                        <mat-select-trigger>
                            <mat-chip-list>
                                <mat-chip *ngFor="let item of formService.form.get('data.countries').value"
                                          [removable]="true" (removed)="onToppingRemoved(item.id, 'countries')">
                                    {{ item.name }}
                                    <mat-icon matChipRemove>cancel</mat-icon>
                                </mat-chip>
                            </mat-chip-list>
                        </mat-select-trigger>
                        <mat-option *ngFor="let item of countries" [value]="item"> {{ item.name }} </mat-option>
                    </mat-select>
                </mat-form-field>
                <mat-form-field fxFlex="50" appearance="outline" floatLabel="always">
                    <mat-label>Language</mat-label>
                    <mat-select formControlName="languages" [compareWith]="compareFn" multiple>
                        <mat-select-trigger>
                            <mat-chip-list>
                                <mat-chip *ngFor="let item of formService.form.get('data.languages').value"
                                          [removable]="true" (removed)="onToppingRemoved(item.id, 'languages')">
                                    {{ item.name }}
                                    <mat-icon matChipRemove>cancel</mat-icon>
                                </mat-chip>
                            </mat-chip-list>
                        </mat-select-trigger>
                        <mat-option *ngFor="let item of languages" [value]="item"> {{ item.name }} </mat-option>
                    </mat-select>
                </mat-form-field>
                <mat-form-field fxFlex="50" appearance="outline" floatLabel="always">
                    <mat-label>Type of content</mat-label>
                    <mat-select formControlName="types" [compareWith]="compareFn" multiple>
                        <mat-select-trigger>
                            <mat-chip-list>
                                <mat-chip *ngFor="let item of formService.form.get('data.types').value"
                                          [removable]="true"
                                          (removed)="onToppingRemoved(item.id, 'types')"> {{ item.name }}
                                    <mat-icon matChipRemove>cancel</mat-icon>
                                </mat-chip>
                            </mat-chip-list>
                        </mat-select-trigger>
                        <mat-option *ngFor="let item of types" [value]="item"> {{ item.name }} </mat-option>
                    </mat-select>
                </mat-form-field>
                <mat-form-field fxFlex="50" appearance="outline" floatLabel="always">
                    <mat-label>Keywords</mat-label>
                    <mat-select formControlName="keywords" [compareWith]="compareFn" multiple>
                        <mat-select-trigger>
                            <mat-chip-list>
                                <mat-chip *ngFor="let item of formService.form.get('data.keywords').value"
                                          [removable]="true" (removed)="onToppingRemoved(item.id, 'keywords')">
                                    {{ item.name }}
                                    <mat-icon matChipRemove>cancel</mat-icon>
                                </mat-chip>
                            </mat-chip-list>
                        </mat-select-trigger>
                        <mat-option *ngFor="let item of keywords" [value]="item"> {{ item.name }} </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
        </mat-card-content>
    </mat-card>
</div>
