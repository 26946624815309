import {AfterViewInit, Component} from '@angular/core';
import {ActivatedRoute, Params, Router} from "@angular/router";
import {Document, WordpressUser} from 'api/model';
import {DocumentsReadFormService} from 'api/forms/documents/read/read.service';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {WordpressUsersListFormService} from '../../../../api/forms/wordpress-users/list/list.service';
import {AuthService} from "../../../shared/services/auth.service";
import * as moment from "moment";
import {MatDialog} from "@angular/material/dialog";
import {ConfirmDialogComponent} from "../../../shared/components/confirm-dialog/confirm-dialog.component";
import {DocumentsDeleteFormService} from "../../../../api/forms/documents/delete/delete.service";

@Component({
    selector: 'app-document-detail-readonly',
    templateUrl: './document-detail-readonly.component.html',
    styleUrls: ['./document-detail-readonly.component.scss']
})
export class DocumentDetailReadonlyComponent implements AfterViewInit {

    private id: number;
    private main_category: any[] = [
        {id: 1, name: 'Research', code: 'research'},
        {id: 2, name: 'Financial', code: 'financial'},
        {id: 3, name: 'Statistics', code: 'statistics'},
        {id: 4, name: 'Environment', code: 'environment'},
        {id: 6, name: 'Regulation', code: 'regulation'},
        {id: 7, name: 'Administrative', code: 'administrative'},
        {id: 8, name: 'Promotion', code: 'promotion'},
        {id: 9, name: 'Chemistry', code: 'chemistry'},
        {id: 10, name: 'Technical Cooperation', code: 'technical_cooperation'},
        {id: 11, name: 'Council Meeting', code: 'council_meeting'},
        {id: 12, name: 'College Meeting', code: 'college_meeting'},
    ];
    public document: any;
    public currentUser$: Observable<WordpressUser[]>;

    constructor(
        private _router: Router,
        private _matDialog: MatDialog,
        private _authService: AuthService,
        private _activatedRoute: ActivatedRoute,
        private _documentsReadFormService: DocumentsReadFormService,
        private _documentsDeleteFormService: DocumentsDeleteFormService,
        public currentUserFS: WordpressUsersListFormService
    ) {
        this._activatedRoute.params
            .subscribe((params: Params) => {
                this.id = params.id || null;
            });

        this._activatedRoute.queryParams
            .subscribe((queryParams: Params) => {
                if (queryParams.origin === 'plugin' && !this._authService.getToken()) {
                    localStorage.setItem('redirectUrl', window.location.href);
                    this._router.navigate(['/apps/login']).then();
                }
            });

        this.currentUser$ = this.currentUserFS.submit(false, false).pipe(
            map(l => l.results)
        );
    }

    ngAfterViewInit(): void {
        if (!this.id) {
            return;
        }

        this._documentsReadFormService.submit({
            id: this.id,
            expand: 'countries,keywords,languages,types,main_category'
        })
            .pipe(
                map((document: Document) => {
                    return {
                        ...document,
                        date: moment(document.date).format('DD/MM/YYYY'),
                        main_category_text: this.main_category.filter(e => e.code === document.main_category).length > 0 ? this.main_category.filter(e => e.code === document.main_category)[0].name : null,
                        keywords_text: this.getDataFiltersInteger(document.keywords),
                        types_text: this.getDataFiltersInteger(document.types),
                        languages_text: this.getDataFiltersInteger(document.languages),
                        countries_text: this.getDataFiltersInteger(document.countries),
                    }
                })
            )
            .subscribe((response: any) => {
                this.document = response;

                this._activatedRoute.queryParams
                    .subscribe((queryParams: Params) => {
                        if (queryParams.delete === 'true') {
                            this.openConfirmDelete(this.document);
                        }
                    });
            });
    }

    private getDataFiltersInteger(data: any[] = []): string {
        let result = '';

        data.forEach((element: any, index: number) => {
            result += index === data.length - 1 ? `${element.name}` : `${element.name} - `;
        });

        return result;
    }

    logout(): void {
        this._authService.logout();
    }

    private openConfirmDelete(document: any) {
        this._matDialog.open(ConfirmDialogComponent, {
            disableClose: true,
            hasBackdrop: true,
            data: {
                title: 'Please confirm deletion',
                message: `Are you sure about deleting this document from the Observatory? the original file in the Private Area won't be affected`
            }
        }).afterClosed()
            .subscribe((result) => {
                if (!result) {
                    this._router.navigate([`/apps/document-detail/${document.id}`]).then()
                    return;
                }

                this.delete(document.id);
            });
    }

    private delete(id) {
        this._documentsDeleteFormService.submit({id: id})
            .subscribe(() => this._router.navigate(['/apps']).then());
    }
}
