import {Component, OnInit} from "@angular/core";
import {Router} from "@angular/router";
import {AuthService} from "../../shared/services/auth.service";
import {WordpressUsersLoginFormService} from "../../../api/forms/wordpress-users/login/login.service";

@Component({
    selector: 'app-login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.scss']
})
export class LoginComponent {

    public hidePassword = true;

    constructor(
        private _router: Router,
        private _authService: AuthService,
        public _wordpressUsersLoginFormService: WordpressUsersLoginFormService,
    ) {
    }

    login(): void {
        const formCopyValue = Object.assign({}, this._wordpressUsersLoginFormService.form.controls.data.value);
        this._authService.login({...formCopyValue})
            .subscribe(() => {
                const redirectUrl = localStorage.getItem('redirectUrl');
                if (redirectUrl) {
                    localStorage.removeItem('redirectUrl');
                    window.location.href = redirectUrl;
                } else {
                    this._router.navigate(['/apps']).then();
                }
            });
    }
}
