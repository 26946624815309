import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouterModule, Routes } from '@angular/router';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { HTTP_INTERCEPTORS, HttpClientModule } from "@angular/common/http";
import { ApiFormsModule } from "../api/forms/apiforms.module";
import { AuthInterceptor } from "./shared/interceptors/auth.interceptor";
import { ProjectMaterialModule } from "./project-material.module";
import { AuthService } from './shared/services/auth.service';
import { WebStorageModule } from 'ngx-store';
import {ConfirmDialogModule} from "./shared/components/confirm-dialog/confirm-dialog.module";
import {FileService} from "./shared/services/file.service";

const routes: Routes = [
    {
        path: 'apps',
        loadChildren: () => import('./apps/apps.module').then(m => m.AppsModule)
    },
    {
        path: '',
        redirectTo: 'apps',
        pathMatch: 'full'
    }
];

@NgModule({
    declarations: [
        AppComponent,
    ],
    imports: [
        BrowserModule,
        RouterModule.forRoot(routes),
        BrowserAnimationsModule,
        HttpClientModule,
        ApiFormsModule,
        ProjectMaterialModule,
        WebStorageModule,
        ConfirmDialogModule
    ],
    providers: [
        AuthService,
        FileService,
        // { provide: HTTP_INTERCEPTORS, useClass: TokenInterceptorService, multi: true },
        { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
    ],
    bootstrap: [AppComponent]
})
export class AppModule {
}
