import {Injectable} from '@angular/core';
import {BehaviorSubject, Observable, throwError} from 'rxjs';
import {tap, catchError, map} from 'rxjs/operators';
import {AppStorageService} from './app-storage.service';
import {UserDetails} from "../../../api/defs/UserDetails";
import {AuthUserReadFormService} from "../../../api/forms/auth/userRead/userRead.service";
import {AuthLogoutCreateFormService} from "../../../api/forms/auth/logoutCreate/logoutCreate.service";
import {Router} from "@angular/router";
import {WordpressUsersLoginFormService} from "../../../api/forms/wordpress-users/login/login.service";

@Injectable()
export class AuthService {

    private _currentUser: BehaviorSubject<UserDetails>;

    constructor(
        private _router: Router,
        private appStorageService: AppStorageService,
        private _currentUserForm: AuthUserReadFormService,
        private _wordpressUsersLoginFormService: WordpressUsersLoginFormService,
        private _profilesLogoutFormService: AuthLogoutCreateFormService
    ) {
        this._currentUser = new BehaviorSubject<UserDetails>(null);
    }

    get currentUser(): Observable<UserDetails> {
        return this._currentUser.asObservable();
    }

    public setToken(token: string): void {
        this.appStorageService.set('token', token);
    }

    public getToken(): string {
        return this.appStorageService.get('token');
    }

    clear() {
        this.appStorageService.clear();
    }

    logout() {
        this._profilesLogoutFormService.submit()
            .subscribe(() => {
                this.clear();
                this._router.navigate(['/apps/login']).then();
            });
    }

    public _readProfileData(): Observable<UserDetails> {
        return this._currentUserForm.submit({expand: 'countries'}, false, false).pipe(
            tap((current: UserDetails) => {
                this._currentUser.next(current);
            })
        );
    }

    login(data): Observable<any> {
        this.clear();
        this._wordpressUsersLoginFormService.form.patchValue({
            data: data,
        });
        return this._wordpressUsersLoginFormService.submit().pipe(
            map((val: any) => {
                this.setToken(val.user_uuid);
            }),
            catchError((error) => {
                return throwError(error);
            })
        );
    }

}
