/* tslint:disable:max-line-length */

import {NgModule} from '@angular/core';

import {DocumentsService} from '../../../controllers/Documents';
import {FormsSharedModule} from '../../forms-shared.module';
import {DocumentsReadFormService} from './read.service';


@NgModule({
  imports: [
    FormsSharedModule,
  ],
  providers: [
    DocumentsService,
    DocumentsReadFormService,
  ],
})
export class DocumentsReadModule {}
