<h1 mat-dialog-title> {{ data.title }} </h1>
<mat-dialog-content> {{ data.message }} </mat-dialog-content>
<mat-dialog-actions>
    <div fxFlex="100" fxLayout="row wrap" fxLayout.lt-md="column" fxLayoutAlign="space-between center">
        <button mat-raised-button color="warn" (click)="close(false)">
            <mat-icon> close</mat-icon>
            Cancel
        </button>
        <button mat-raised-button color="primary" (click)="close(true)">
            <mat-icon> done</mat-icon>
            Accept
        </button>
    </div>
</mat-dialog-actions>
