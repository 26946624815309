/* tslint:disable:max-line-length */

import {NgModule} from '@angular/core';

import { IndexDocumentsIndexDocumentsModule } from './indexDocuments/indexDocuments.module';

@NgModule({
  imports: [
    IndexDocumentsIndexDocumentsModule,
  ],
  exports: [
    IndexDocumentsIndexDocumentsModule,
  ],
})
export class IndexDocumentsFormModule {}
