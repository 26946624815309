/* tslint:disable:max-line-length */

import {NgModule} from '@angular/core';

import { DocumentTypesDocumentTypesModule } from './documentTypes/documentTypes.module';

@NgModule({
  imports: [
    DocumentTypesDocumentTypesModule,
  ],
  exports: [
    DocumentTypesDocumentTypesModule,
  ],
})
export class DocumentTypesFormModule {}
