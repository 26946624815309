/* tslint:disable:max-line-length */

import {NgModule} from '@angular/core';

import { KeywordsKeywordsModule } from './keywords/keywords.module';

@NgModule({
  imports: [
    KeywordsKeywordsModule,
  ],
  exports: [
    KeywordsKeywordsModule,
  ],
})
export class KeywordsFormModule {}
