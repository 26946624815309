/* tslint:disable:max-line-length */

import {NgModule} from '@angular/core';

import {DocumentLanguagesService} from '../../../controllers/DocumentLanguages';
import {FormsSharedModule} from '../../forms-shared.module';
import {DocumentLanguagesDocumentLanguagesFormService} from './documentLanguages.service';


@NgModule({
  imports: [
    FormsSharedModule,
  ],
  providers: [
    DocumentLanguagesService,
    DocumentLanguagesDocumentLanguagesFormService,
  ],
})
export class DocumentLanguagesDocumentLanguagesModule {}
