import {Component, OnInit, ViewChild} from '@angular/core';
import {FormControl, FormGroup} from "@angular/forms";
import {KeywordsKeywordsFormService} from "../../../api/forms/keywords/keywords/keywords.service";
import {CountriesCountriesFormService} from "../../../api/forms/countries/countries/countries.service";
import {DocumentLanguagesDocumentLanguagesFormService} from "../../../api/forms/document-languages/documentLanguages/documentLanguages.service";
import {DocumentTypesDocumentTypesFormService} from '../../../api/forms/document-types/documentTypes/documentTypes.service';
import {
    DocumentLanguage,
    DocumentType,
    DocumentTypesDocumentTypes,
    KeywordsKeywords,
    Country,
    Document,
    IndexDocumentsIndexDocuments,
    ElasticDocument,
    CountriesCountries,
    DocumentLanguagesDocumentLanguages,
    WordpressGroup, WordpressUser
} from 'api/model';
import {map, tap} from 'rxjs/operators';
import * as moment from 'moment';
import {MatPaginator, PageEvent} from '@angular/material/paginator';
import {WordpressUsersListFormService} from '../../../api/forms/wordpress-users/list/list.service';
import {AuthService} from "../../shared/services/auth.service";
import {IndexDocumentsIndexDocumentsFormService} from "../../../api/forms/index-documents/indexDocuments/indexDocuments.service";
import {DocumentsWordpressGroupsFormService} from "../../../api/forms/documents/wordpressGroups/wordpressGroups.service";
import {Observable} from 'rxjs';
import {Router} from "@angular/router";

@Component({
    selector: 'app-document',
    templateUrl: './document.component.html',
    styleUrls: ['./document.component.scss']
})
export class DocumentComponent implements OnInit {

    public keywords: any[] = [];
    public main_category: any[] = [
        {id: 1, name: 'Research', code: 'research'},
        {id: 2, name: 'Financial', code: 'financial'},
        {id: 3, name: 'Statistics', code: 'statistics'},
        {id: 4, name: 'Environment', code: 'environment'},
        {id: 6, name: 'Regulation', code: 'regulation'},
        {id: 7, name: 'Administrative', code: 'administrative'},
        {id: 8, name: 'Promotion', code: 'promotion'},
        {id: 9, name: 'Chemistry', code: 'chemistry'},
        {id: 10, name: 'Technical Cooperation', code: 'technical_cooperation'},
        {id: 11, name: 'Council Meeting', code: 'council_meeting'},
        {id: 12, name: 'College Meeting', code: 'college_meeting'},
    ];
    public documents: Document[] = [];
    public countries: Country[] = [];
    public types: DocumentType[] = [];
    public languages: DocumentLanguage[] = [];
    public groups: any[] = [];
    public range = new FormGroup({
        start: new FormControl(),
        end: new FormControl()
    });
    public _pageSize = 3;
    public _pageIndex = 0;
    public _totalData: number;

    public currentUser$: Observable<WordpressUser[]>;

    @ViewChild(MatPaginator) paginator: MatPaginator;

    constructor(
        public _wordpressUsersListFormService: WordpressUsersListFormService,
        public _indexDocumentsIndexDocumentsFormService: IndexDocumentsIndexDocumentsFormService,
        private _router: Router,
        private _authService: AuthService,
        private _keywordsKeywordsFormService: KeywordsKeywordsFormService,
        private _countriesCountriesFormService: CountriesCountriesFormService,
        private _documentTypesDocumentTypesFormService: DocumentTypesDocumentTypesFormService,
        private _documentLanguagesDocumentLanguagesFormService: DocumentLanguagesDocumentLanguagesFormService,
        private _documentsWordpressGroupsFormService: DocumentsWordpressGroupsFormService,
    ) {
        this.loadFilters();
    }

    ngOnInit() {
        this.currentUser$ = this._wordpressUsersListFormService.submit(false, false).pipe(
            map(l => l.results)
        );

        this.loadList();
        this._indexDocumentsIndexDocumentsFormService.form.valueChanges
            .subscribe(() => this.loadList());

        this.range.valueChanges
            .subscribe((values) => {
                if (this.range.valid) {
                    this._indexDocumentsIndexDocumentsFormService.form.patchValue({
                        from_date: values.start ? moment(values.start).format('YYYY-MM-DD') : null,
                        to_date: values.end ? moment(values.end).format('YYYY-MM-DD') : null
                    });
                    this.loadList();
                }
            });
    }

    loadList(): void {
        const filters = Object.assign({}, this._indexDocumentsIndexDocumentsFormService.form.value);
        filters.keywords = this.convertArrayToString('keywords');
        filters.main_category = filters.main_category ? filters.main_category.code.toString().toLowerCase() : null;
        filters.countries = this.convertArrayToString('countries');
        filters.types = this.convertArrayToString('types');
        filters.languages = this.convertArrayToString('languages');
        filters.groups = this.convertArrayToString('groups');

        this._indexDocumentsIndexDocumentsFormService.submit({
            ...filters
            , limit: this._pageSize
            , offset: this._pageIndex === 0 ? this._pageIndex : (this._pageIndex * this._pageSize)
            , expand: 'countries,keywords,languages,types,main_category'
        }).pipe(
            map((response: IndexDocumentsIndexDocuments) => {
                return {
                    ...response,
                    results: response.results.map((document: ElasticDocument) => {
                        return {
                            ...document,
                            date: moment(document.date).format('DD/MM/YYYY'),
                            main_category_text: this.main_category.filter(e => e.code === document.main_category).length > 0 ? this.main_category.filter(e => e.code === document.main_category)[0].name : null,
                            keywords_text: this.getDataFiltersInteger(document.keywords),
                            types_text: this.getDataFiltersInteger(document.types),
                            languages_text: this.getDataFiltersInteger(document.languages),
                            countries_text: this.getDataFiltersInteger(document.countries),
                        }
                    })
                }
            }),
            tap(response => {
                this._totalData = response.count;
                this.documents = response.results;
            })
        ).subscribe();
    }

    loadFilters(): void {
        this._countriesCountriesFormService.submit({limit: 9999})
            .subscribe((response: CountriesCountries) => this.countries = response.results);

        this._documentLanguagesDocumentLanguagesFormService.submit({limit: 9999})
            .subscribe((response: DocumentLanguagesDocumentLanguages) => this.languages = response.results);

        this._documentTypesDocumentTypesFormService.submit({limit: 9999})
            .subscribe((response: DocumentTypesDocumentTypes) => this.types = response.results);

        this._keywordsKeywordsFormService.submit({limit: 9999})
            .subscribe((response: KeywordsKeywords) => this.keywords = response.results);

        this._documentsWordpressGroupsFormService.submit({limit: 9999})
            .subscribe((response: WordpressGroup[]) => this.groups = response);
    }

    private getDataFiltersInteger(data: any[] = []): string {
        let result = '';

        data.forEach((element: any, index: number) => {
            result += index === data.length - 1 ? `${element.name}` : `${element.name} - `;
        });

        return result;
    }

    changePagination(event: PageEvent): void {
        this._pageSize = event.pageSize;
        this._pageIndex = event.pageIndex;
        this.paginator.pageSize = this._pageSize;
        this.loadList();
    }

    onToppingRemoved(id: number, property: string) {
        if (property === 'main_category') {
            this._indexDocumentsIndexDocumentsFormService.form.get(`${property}`).setValue(null);
        } else {
            let data = this._indexDocumentsIndexDocumentsFormService.form.get(`${property}`).value as any[];
            data = data.filter(e => e.id !== id);
            this._indexDocumentsIndexDocumentsFormService.form.get(`${property}`).setValue(data);
        }
    }

    convertArrayToString(property: string): string {
        let result = '';
        const data = this._indexDocumentsIndexDocumentsFormService.form.value[property] || [];
        data.forEach((e, index) => {
            result += index === data.length - 1 ? `${e.name}` : `${e.name},`
        });

        return result;
    }

    login(): void {
        this._router.navigate(['/apps/login']).then();
    }

    logout(): void {
        this._authService.logout();
    }
}
