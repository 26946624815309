/* tslint:disable:max-line-length */

import {NgModule} from '@angular/core';

import {KeywordsService} from '../../../controllers/Keywords';
import {FormsSharedModule} from '../../forms-shared.module';
import {KeywordsKeywordsFormService} from './keywords.service';


@NgModule({
  imports: [
    FormsSharedModule,
  ],
  providers: [
    KeywordsService,
    KeywordsKeywordsFormService,
  ],
})
export class KeywordsKeywordsModule {}
