import {NgModule} from '@angular/core';
import {Route, RouterModule} from '@angular/router';

const routes: Route[] = [
    {
        path: '',
        loadChildren: () => import('./document/document.module').then(m => m.DocumentModule)
    },
    {
        path: 'login',
        loadChildren: () => import('./login/login.module').then(m => m.LoginModule)
    },
    {
        path: 'document',
        loadChildren: () => import('./document/document-detail/document-detail.module').then(m => m.DocumentDetailModule)
    },
    {
        path: 'document-detail',
        loadChildren: () => import('./document/document-detail-readonly/document-detail-readonly.module').then(m => m.DocumentDetailReadonlyModule)
    }
];

@NgModule({
    imports: [
        RouterModule.forChild(routes),
    ],
    declarations: []
})
export class AppsModule {
}
