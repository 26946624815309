/* tslint:disable:max-line-length */

import {NgModule} from '@angular/core';

import { DocumentsListModule } from './list/list.module';
import { DocumentsCreateModule } from './create/create.module';
import { DocumentsCheckIfExistsModule } from './checkIfExists/checkIfExists.module';
import { DocumentsWordpressGroupsModule } from './wordpressGroups/wordpressGroups.module';
import { DocumentsReadModule } from './read/read.module';
import { DocumentsUpdateModule } from './update/update.module';
import { DocumentsPartialUpdateModule } from './partialUpdate/partialUpdate.module';
import { DocumentsDeleteModule } from './delete/delete.module';

@NgModule({
  imports: [
    DocumentsListModule,
    DocumentsCreateModule,
    DocumentsCheckIfExistsModule,
    DocumentsWordpressGroupsModule,
    DocumentsReadModule,
    DocumentsUpdateModule,
    DocumentsPartialUpdateModule,
    DocumentsDeleteModule,
  ],
  exports: [
    DocumentsListModule,
    DocumentsCreateModule,
    DocumentsCheckIfExistsModule,
    DocumentsWordpressGroupsModule,
    DocumentsReadModule,
    DocumentsUpdateModule,
    DocumentsPartialUpdateModule,
    DocumentsDeleteModule,
  ],
})
export class DocumentsFormModule {}
