<div fxFill fxFlex="100" fxLayout="column" fxLayoutGap="12px" class="padding-40" *ngIf="document">
    <div fxFlex="100" fxLayout="row" fxLayoutAlign="start center" *ngIf="(currentUser$ | async) as currentUser">
        <a mat-raised-button type="button" color="primary" (click)="logout()"
           [disabled]="(_documentsReadFormService.loading$ | async) || (_documentsDeleteFormService.loading$ | async)">
            <mat-icon> logout</mat-icon>
            <span *ngIf="currentUser.length > 0">LOG OUT</span>
            <span *ngIf="currentUser.length === 0">LOG IN</span>
        </a>
    </div>
    <mat-progress-bar mode="indeterminate" color="primary"
                      *ngIf="(_documentsReadFormService.loading$ | async) || (_documentsDeleteFormService.loading$ | async)"></mat-progress-bar>
    <mat-card class="mat-elevation-z4">
        <mat-card-content fxFlex="100" fxLayout="column" fxLayoutGap="12px">
            <div fxFlex="100" fxLayout="row" fxLayoutAlign="space-between center" fxLayoutGap="12px">
                <button mat-raised-button type="button" color="primary" routerLink="/apps"
                        [disabled]="(_documentsReadFormService.loading$ | async) || (_documentsDeleteFormService.loading$ | async)">
                    <mat-icon> arrow_left</mat-icon>
                    GO TO DOCUMENTS
                </button>
            </div>
            <div fxFlex="100" fxLayout="row wrap" fxLayout.lt-md="column" fxLayoutGap="12px grid" class="margin-top-20">
                <div fxFlex="100" fxLayout="row wrap" fxLayout.lt-md="column"
                     fxLayoutGap="12px grid">
                    <div fxFlex="70" fxLayout="column">
                        <h1 fxFlex="100" class="bold">
                            {{ document.name }} &nbsp;
                            <a mat-mini-fab matTooltip="Download" color="primary" size="20" [href]="document.link"
                               [download]="document.link">
                                <mat-icon> arrow_downward</mat-icon>
                            </a>
                        </h1>
                        <span fxFlex="100"> {{ document['keywords_text'] }} </span>
                    </div>
                    <div fxFlex="30" fxLayout="column" class="text-end">
                        <span fxFlex="100"> {{ document.date }} </span>
                        <div fxFlex="100">
                            <span> {{ document['main_category_text'] }} </span>
                            <span> {{ document['types_text'] }} </span>
                        </div>
                        <span fxFlex="100" class="font-size-15"
                              *ngIf="document['languages_text'].length > 0">
                            <mat-icon class="font-size-15"> g_translate </mat-icon>
                            {{ document['languages_text'] }}
                        </span>
                        <span fxFlex="100" class="font-size-15"
                              *ngIf="document['countries_text'].length > 0">
                            <mat-icon class="font-size-15"> flag </mat-icon>
                            {{ document['countries_text'] }}
                        </span>
                    </div>
                    <div fxFlex="100" class="margin-top-20"> {{ document.description }} </div>
                </div>
            </div>
            <div fxFlex="100" fxLayout="row" fxLayoutAlign="end center" fxLayoutGap="12px" class="margin-top-20"
                 *ngIf="(currentUser$ | async) as currentUser">
                <button *ngIf="currentUser.length && currentUser[0].is_observatory_manager"
                        mat-raised-button type="button" color="primary" routerLink="/apps/document/{{document.id}}"
                        [disabled]="(_documentsReadFormService.loading$ | async) || (_documentsDeleteFormService.loading$ | async)">
                    <mat-icon> edit</mat-icon>
                    EDIT OBSERVATORY METADATA
                </button>
                <button *ngIf="currentUser.length && currentUser[0].is_observatory_manager"
                        mat-raised-button type="button" color="warn" (click)="openConfirmDelete(document)"
                        [disabled]="(_documentsReadFormService.loading$ | async) || (_documentsDeleteFormService.loading$ | async)">
                    <mat-icon> delete</mat-icon>
                    DELETE FROM OBSERVATORY
                </button>
            </div>
        </mat-card-content>
    </mat-card>
</div>
