/* tslint:disable:max-line-length */

import {NgModule} from '@angular/core';

import {DocumentsService} from '../../../controllers/Documents';
import {FormsSharedModule} from '../../forms-shared.module';
import {DocumentsListFormService} from './list.service';


@NgModule({
  imports: [
    FormsSharedModule,
  ],
  providers: [
    DocumentsService,
    DocumentsListFormService,
  ],
})
export class DocumentsListModule {}
