import {NgModule} from "@angular/core";
import {CommonModule} from "@angular/common";
import {FlexLayoutModule} from "@angular/flex-layout";
import {ConfirmDialogComponent} from "./confirm-dialog.component";
import {MatButtonModule} from "@angular/material/button";
import {MatIconModule} from "@angular/material/icon";
import {MatDialogModule} from "@angular/material/dialog";

@NgModule({
    imports: [
        CommonModule,
        FlexLayoutModule,
        MatButtonModule,
        MatIconModule,
        MatDialogModule
    ],
    declarations: [ConfirmDialogComponent],
    entryComponents: [ConfirmDialogComponent],
})
export class ConfirmDialogModule {
}
