<div fxFill fxFlex="100" fxLayout="column" fxLayoutGap="12px" class="padding-40">
    <div fxFlex="100" fxLayout="row" fxLayoutAlign="start center" *ngIf="(currentUser$ | async) as currentUser">
        <a mat-raised-button type="button" color="primary" (click)="currentUser.length > 0 ? logout() : login()"
           [disabled]="(_indexDocumentsIndexDocumentsFormService.loading$ | async)">
            <mat-icon> {{ currentUser.length > 0 ? 'logout' : 'login' }}</mat-icon>
            <span> {{ currentUser.length > 0 ? 'LOG OUT' : 'LOG IN' }} </span>
        </a>
    </div>
    <mat-progress-bar mode="indeterminate" color="primary"
                      *ngIf="(_indexDocumentsIndexDocumentsFormService.loading$ | async)"></mat-progress-bar>
    <mat-card class="mat-elevation-z4">
        <mat-card-content fxFlex="100" fxLayout="column" fxLayoutGap="12px">
            <div fxFlex="100" fxLayout="row" fxLayoutAlign="end center" *ngIf="(currentUser$ | async) as currentUser">
                <a mat-raised-button type="button" color="primary" [routerLink]="['/apps/document']"
                   *ngIf="currentUser.length > 0"
                   [queryParams]="{source: 'public_area', visibility: 'public'}"
                   [disabled]="(_indexDocumentsIndexDocumentsFormService.loading$ | async)">
                    <mat-icon> add</mat-icon>
                    NEW DOCUMENT
                </a>
            </div>
            <div fxFlex="100" fxLayout="row wrap" fxLayout.lt-md="column" fxLayoutGap="12px grid"
                 [formGroup]="_indexDocumentsIndexDocumentsFormService.form">
                <mat-form-field fxFlex="50" appearance="outline" floatLabel="always">
                    <mat-label>Keywords</mat-label>
                    <mat-select formControlName="keywords" multiple>
                        <mat-select-trigger>
                            <mat-chip-list>
                                <mat-chip
                                    *ngFor="let item of _indexDocumentsIndexDocumentsFormService.form.get('keywords').value"
                                    [removable]="true" (removed)="onToppingRemoved(item.id, 'keywords')">
                                    {{ item.name }}
                                    <mat-icon matChipRemove>cancel</mat-icon>
                                </mat-chip>
                            </mat-chip-list>
                        </mat-select-trigger>
                        <mat-option *ngFor="let item of keywords" [value]="item"> {{ item.name }} </mat-option>
                    </mat-select>
                </mat-form-field>
                <mat-form-field fxFlex="50" appearance="outline" floatLabel="always">
                    <mat-label>Title and description</mat-label>
                    <input matInput placeholder="Search..." formControlName="search">
                </mat-form-field>
                <div fxFlex="100" fxLayout="row wrap" fxLayout.lt-md="column" fxLayoutGap="12px grid">
                    <div fxFlex="30" fxLayout="column" fxLayoutGap="12px" class="border-right">
                        <mat-form-field appearance="outline">
                            <mat-label>Date of the document</mat-label>
                            <mat-date-range-input [formGroup]="range" [rangePicker]="picker">
                                <input matStartDate formControlName="start" placeholder="Start date">
                                <input matEndDate formControlName="end" placeholder="End date">
                            </mat-date-range-input>
                            <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                            <mat-date-range-picker #picker></mat-date-range-picker>
                            <mat-error *ngIf="range.controls.start.hasError('matStartDateInvalid')">Invalid start date
                            </mat-error>
                            <mat-error *ngIf="range.controls.end.hasError('matEndDateInvalid')">Invalid end date
                            </mat-error>
                        </mat-form-field>
                        <mat-form-field fxFlex="100" appearance="outline" floatLabel="always">
                            <mat-label>Main category</mat-label>
                            <mat-select formControlName="main_category">
                                <mat-select-trigger>
                                    <mat-chip-list>
                                        <mat-chip [removable]="true"
                                                  (removed)="onToppingRemoved(_indexDocumentsIndexDocumentsFormService.form.get('main_category').value?.id, 'main_category')">
                                            {{ _indexDocumentsIndexDocumentsFormService.form.get('main_category').value?.name }}
                                            <mat-icon matChipRemove>cancel</mat-icon>
                                        </mat-chip>
                                    </mat-chip-list>
                                </mat-select-trigger>
                                <mat-option *ngFor="let item of main_category" [value]="item"> {{ item.name }}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                        <mat-form-field fxFlex="100" appearance="outline" floatLabel="always">
                            <mat-label>Related origin</mat-label>
                            <mat-select formControlName="countries" multiple>
                                <mat-select-trigger>
                                    <mat-chip-list>
                                        <mat-chip
                                            *ngFor="let item of _indexDocumentsIndexDocumentsFormService.form.get('countries').value"
                                            [removable]="true" (removed)="onToppingRemoved(item.id, 'countries')">
                                            {{ item.name }}
                                            <mat-icon matChipRemove>cancel</mat-icon>
                                        </mat-chip>
                                    </mat-chip-list>
                                </mat-select-trigger>
                                <mat-option *ngFor="let item of countries" [value]="item"> {{ item.name }} </mat-option>
                            </mat-select>
                        </mat-form-field>
                        <mat-form-field fxFlex="100" appearance="outline" floatLabel="always">
                            <mat-label>Type of content</mat-label>
                            <mat-select formControlName="types" multiple>
                                <mat-select-trigger>
                                    <mat-chip-list>
                                        <mat-chip
                                            *ngFor="let item of _indexDocumentsIndexDocumentsFormService.form.get('types').value"
                                            [removable]="true" (removed)="onToppingRemoved(item.id, 'types')"> {{
                                            item.name }}
                                            <mat-icon matChipRemove>cancel</mat-icon>
                                        </mat-chip>
                                    </mat-chip-list>
                                </mat-select-trigger>
                                <mat-option *ngFor="let item of types" [value]="item"> {{ item.name }} </mat-option>
                            </mat-select>
                        </mat-form-field>
                        <mat-form-field fxFlex="100" appearance="outline" floatLabel="always">
                            <mat-label>Language</mat-label>
                            <mat-select formControlName="languages" multiple>
                                <mat-select-trigger>
                                    <mat-chip-list>
                                        <mat-chip
                                            *ngFor="let item of _indexDocumentsIndexDocumentsFormService.form.get('languages').value"
                                            [removable]="true" (removed)="onToppingRemoved(item.id, 'languages')">
                                            {{ item.name }}
                                            <mat-icon matChipRemove>cancel</mat-icon>
                                        </mat-chip>
                                    </mat-chip-list>
                                </mat-select-trigger>
                                <mat-option *ngFor="let item of languages" [value]="item"> {{ item.name }} </mat-option>
                            </mat-select>
                        </mat-form-field>
                        <mat-form-field fxFlex="100" appearance="outline" floatLabel="always" *ngIf="groups.length">
                            <mat-label>Group (Private Area)</mat-label>
                            <mat-select formControlName="groups" multiple>
                                <mat-select-trigger>
                                    <mat-chip-list>
                                        <mat-chip
                                            *ngFor="let item of _indexDocumentsIndexDocumentsFormService.form.get('groups').value"
                                            [removable]="true" (removed)="onToppingRemoved(item.id, 'groups')">
                                            {{ item.name }}
                                            <mat-icon matChipRemove>cancel</mat-icon>
                                        </mat-chip>
                                    </mat-chip-list>
                                </mat-select-trigger>
                                <mat-option *ngFor="let item of groups" [value]="item"> {{ item.name }} </mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                    <div fxFlex="70" fxLayout="column" fxLayoutGap="12px grid">
                        <div class="text-center">{{_totalData}} results</div>
                        <div *ngFor="let item of documents" fxFlex="33" fxLayout="column" fxLayoutGap="12px"
                             style="margin-left: 2%" routerLink="/apps/document-detail/{{item.id}}">
                            <div fxFlex="100" class="border-bottom" fxLayout="row wrap" fxLayout.lt-md="column"
                                 fxLayoutGap="12px grid">
                                <div fxFlex="70" fxLayout="column">
                                    <h1 fxFlex="100" class="bold"> {{ item.name }} &nbsp;
                                        <a mat-mini-fab matTooltip="Download" color="primary" size="20"
                                           [href]="item.link" [download]="item.link"
                                           (click)="$event.stopPropagation();">
                                            <mat-icon> arrow_downward</mat-icon>
                                        </a>
                                    </h1>
                                    <span fxFlex="100"> {{ item['keywords_text'] }} </span>
                                    <span fxFlex="100" class="text-description"> {{ item['description'] }} </span>
                                </div>
                                <div fxFlex="30" fxLayout="column" class="text-end">
                                    <span fxFlex="100"> {{ item.date }} </span>
                                    <div fxFlex="100">
                                        <span> {{ item['main_category_text'] }} </span>
                                        <span> {{ item['types_text'] }} </span>
                                    </div>
                                    <span fxFlex="100" class="font-size-15" *ngIf="item['languages_text'].length > 0">
                                        <mat-icon class="font-size-15"> g_translate </mat-icon>
                                        {{ item['languages_text'] }}
                                    </span>
                                    <span fxFlex="100" class="font-size-15" *ngIf="item['countries_text'].length > 0">
                                        <mat-icon class="font-size-15"> flag </mat-icon>
                                        {{ item['countries_text'] }}
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <mat-paginator fxFlex="100" [pageSize]="_pageSize" [length]="_totalData" [pageIndex]="_pageIndex"
                                   [pageSizeOptions]="[3, 5]" (page)="changePagination($event)">
                    </mat-paginator>
                </div>
            </div>
        </mat-card-content>
    </mat-card>
</div>
